import React from 'react'
// import '/index.css';
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { text } from '@fortawesome/fontawesome-svg-core';

function Contact() {

  return (
    <div className='container-80 text-center'>
      <h1 className='h1'>Welcome to My Application!</h1>


      <p className='mt-2'>We'd love to hear from you! If you have any questions, comments, or suggestions, please don't hesitate to reach out.</p>

      <h2 className='mt-5'>Get in Touch</h2>
      <ul className='ul-none'>
        <li>Email: <a href="mailto:yazanjaradat88@gmail.com">yazanjaradat88@gmail.com</a> </li>
        <li>Phone: (+962) 799-782965 </li>
      </ul>

      <h2 className='mt-5'>Social Media</h2>
      <ul className='ul-none'>
        <li className='mt-3'>  <a href="#"> <FontAwesomeIcon size='2x' className='px-2' icon={faFacebookSquare} /> &&  <FontAwesomeIcon size='2x' className='px-2' icon={faLinkedin} /> </a> </li>
      </ul>

      <h2 className='mt-5'>Our Team</h2>
      <ul className='ul-none'>
        <li>**Yazan Ahmad** Founder & CEO </li>
      </ul>



    </div>
  )

};

export default Contact
