import logo from './logo.svg';
import './App.css';
import CounterBtn from './components/CounterBtn'
import Menu from './components/Menu';
import TextAreaCon from './components/TextAreaCon';


import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Contact from './components/Contact';
import About from './components/About';

function App() {
  return (
  
    <div className="App bg-react">
    <BrowserRouter>

    <Menu></Menu>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/About" element={<About />} />

        
      </Routes>
    </BrowserRouter>
      
      <header className="App-header">

        
  
        <a
          className="App-link mt-3"
          href="#"
          // target="_blank"
          rel="noopener noreferrer"
        >
          Yazan Ahmad Jaradat
          
        </a>

        <p className='mt-4'>
          All Right Reserved <code>@Yazan Ahmad</code> 2024.
        </p>

        <br/>
        <div>
          {/* <CounterBtn start={10}/> */}
        </div>
      </header>
    </div>
    
  );
}

export default App;
