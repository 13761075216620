import React, { useState } from 'react'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import Table from './Table';
import Tlegend from './Legend';
import parsingCart from './parser2.js';


export default function TextAreaCon(params) {

  const [isLoaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [text, setText] = useState("");
  const [tax, setTax] = useState(0.1);
  const [extra, setExtra] = useState(0);
  const [titels, settitels ] = useState({});

  const handleClick = () => {

    if (text == '') {
      alert("HTML code should not be empty!!!");
      return;
    }

    try {
      setLoaded(true)
      let cart_content = parsingCart(text, tax, extra)

      const _titels = {
        real_total_price :  cart_content.real_total_price,
        rewards:  cart_content.rewards,
        pkg_weight:  cart_content.pkg_weight,
        pkg_shipping:  cart_content.pkg_shipping
      }

      settitels(_titels)
   
      console.log('..............................................');
      console.log('::::::::::::::::: pkg_weight   : ' + titels.pkg_weight);
      console.log('::::::::::::::::: pkg_shipping : ' + titels.pkg_shipping);
      console.log('::::::::::::::::: subtotal     : ' + cart_content.subtotal);
      console.log('::::::::::::::::: rewards      : ' + titels.rewards);
      console.log('::::::::::::::::: Total Price  : ' + titels.real_total_price);

      setItems(cart_content.items);
      setText('')
    }

    catch (error) {
      alert('Invalid HTML code !!! \n' + error.message);
    }
  };




  const openFileDialog = ()=> {
    const fileInput = document.getElementById('itemFile');
    fileInput.click(); // Simulates a click on the hidden file input
  }

  function handleFileSelection(event) {
    const fileList = event.target.files;
  
    if (!fileList.length) return; // Check if a file is selected
  
    const reader = new FileReader();
  
    reader.onload = function(e) {
      setItems( JSON.parse(e.target.result));
    };
  
    reader.readAsText(fileList[0]);
  }

  return (
    <div className='col-md-12 pt-1 text-center defText'>
      <div className='form-group'>
        <textarea id="w3review" name="w3review" rows="4" className='txt-small text-dark w-50'
          value={text} onChange={(e) => setText(e.target.value)} cols="40" placeholder='Paste HTML here...'>
        </textarea>

        <div>
          <label htmlFor="txttax"> Taxes</label>
          <input type="number" id='txttax' className='m-2' min={0.05} max={0.9} step={0.05} value={tax} onChange={(e) => setTax(e.target.value)} />%
          &#160;&#160;&#160; &#160;&#160;&#160;
          <label htmlFor="txtextra"> Extra</label>
          <input type="number" id='txtextra' className='m-2' min={0} max={25} step={0.25} value={extra} onChange={(e) => setExtra(e.target.value)} />JOD
        </div>
      </div>

      <button onClick={handleClick} className='w-50 mbutton'>Parse iHerb Shopping Cart</button>

      <button onClick={openFileDialog} className='w-50 mbutton m-2'> Or Import Cart Json File </button>
      <input type="file" id="itemFile" onChange={handleFileSelection} accept=".json" style={{display: 'none'}}></input>  


      { isLoaded && <Tlegend params={titels} />  }
      {/* <Tlegend params={titels} />  */}
      <Table items={items} key={items.id} />

    </div>

  );
}

