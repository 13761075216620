import React from 'react'
import '../index.css';
import 'bootstrap/dist/css/bootstrap.css';
import ReactPlayer from 'react-player'
const VIDEO_PATH_01 = '../../i01.mp4';
const VIDEO_PATH_02 = '../../i02.mp4';
const VIDEO_PATH_03 = '../../i03.mp4';

export default function About() {

  return (
    <div className='container-80'>
      <h1 className='h1'>iHerb Cart Price Calculator</h1>
      <p className='mt-4'>This React application helps you calculate the final price for each item in your iHerb cart, including shipping, taxes, and any other relevant costs. You can easily export the detailed results as a JSON file for future reference or import previously saved data.</p>

      <h2 className='mt-4'>Features</h2>
      <ul>
        <li>Parse iHerb Cart Data:** Copy and paste the HTML content of the body tag from your iHerb cart page into the text box below. Click the "Parse" button, and the app will extract item details.</li>
        <li>Calculate Final Price:** The app calculates the final price for each item by considering the original price, quantity, and any additional costs you define (e.g., shipping, taxes).</li>
        <li>Export to JSON:** Export the detailed results, including item information and final prices, as a JSON file for later reference.</li>
        <li>Import from JSON:** Import previously exported JSON files containing cart data back into the app for quick access and price recalculations.</li>
      </ul>

      <h2 className='mt-4'>Benefits</h2>
      <ul>
        <li>Accurate Cost Estimation:** Get a clear picture of your final iHerb purchase cost before completing the checkout process.</li>
        <li>Data Persistence:** Save your iHerb cart data as JSON files and access it later for price updates or order comparisons.</li>
        <li>Improved Shopping Experience:** This app streamlines the iHerb shopping experience by simplifying price calculations and data management.</li>
      </ul>


      <div className="row">
        <div className="col-md-12 text-center">
          <h2 className='mt-4'> User Guide </h2>
          <h5 className='h5'>
            Importing Iherb-cart HTML code to iherb cart price calculator and using the item-grouper.
            <br />
            <small> * You can use the exported JSON file as an archive for future reference *</small>
          </h5>
          <div>
            <ReactPlayer className='vedio' url={VIDEO_PATH_01} controls={true} />
          </div>
        </div>
      </div>


      <div className="row mt-5">
        <div className="col-md-12 text-center">
          <h5 className='h5'>
          Another method to use the item-grouper
            
          </h5>
          <div>
            <ReactPlayer className='vedio' url={VIDEO_PATH_02} controls={true} />
          </div>
        </div>
      </div>



      <div className="row mt-5">
        <div className="col-md-12 text-center">
          <h5 className='h5'>
          Importing the archived JSON file to iherb cart price calculator.
          </h5>
          <div>
            <ReactPlayer className='vedio' url={VIDEO_PATH_03} controls={true} />
          </div>
        </div>
      </div>

      <p className='text-center mt-3'>**Please Note:** This application is designed to work with the HTML structure of the iHerb cart page.  Changes to the iHerb website may require adjustments to the parsing logic within the React application.</p>


    </div>
  )

};
