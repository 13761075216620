import React from 'react'
import TextAreaCon from './TextAreaCon';
import '../index.css';
import 'bootstrap/dist/css/bootstrap.css';

export default function Home() {

    return(
        <div className='mb-5 m-2'>
            <TextAreaCon/>
        </div>

    )
    
};
