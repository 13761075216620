const DOMParser = require("dom-parser");

function parsingCart(text, tax, extra) {
  // console.log(".................." + extra);
  const htmlContent = text;

  let tShipping = 0;

  const parser = new DOMParser();
  const dom = parser.parseFromString(htmlContent);

  const tPrice = parseFloat(dom.getElementsByAttribute("data-qa-element", "subtotal")[0].textContent.trim().match(/\d+\.\d+/)[0]);
  let tshipstring = dom.getElementsByAttribute("data-qa-element", "shipping-total")[0].textContent.trim();

  let tweight = parseFloat(dom.getElementsByClassName("css-10wjf3w")[0].textContent.trim().match(/\d+\.\d+/)[0]);
  //
  if (tshipstring.toUpperCase().includes('JOD') ) {
    tShipping = parseFloat(tshipstring.match(/\d+\.\d+/)[0]);
  } else {
    tShipping = 0;
  }

  // const rewards = parseFloat(dom.getElementsByClassName('css-lhj5ms')[0].textContent.trim().match(/\d+\.\d+/)[0]); //rewards and discount have the same css class
  const rewards = parseFloat(dom.getElementsByAttribute('data-qa-element', "rewards-credit")[0].textContent.trim().match(/\d+\.\d+/)[0]);
  const totaltaxes = parseFloat(dom.getElementsByAttribute('data-qa-element', "tax-total")[0].textContent.trim().match(/\d+\.\d+/)[0]);


  const r_total_price = parseFloat(dom.getElementsByClassName('css-1ivozpc')[0].textContent.trim().match(/\d+\.\d+/)[0]);



  // const prices = parseFloat( dom.getElementsByClassName('css-p064rq')[0].textContent.trim().match(/\d+\.\d+/)[0] );

  const cartItems = dom.getElementsByClassName("css-1h2xo4b");
  const parsedItems = [];

  let count = 0;
  for (let item of cartItems) {
    const name = item.getElementsByClassName("css-1wm8slt")[0].textContent.trim();
    const weight = item.getElementsByClassName("css-vov82o")[1].textContent.trim();
    // console.log(">>>>>>>>>>>" + weight+ "...........................");
    const _weight =  parseFloat(weight.substring(7, weight.length - 2));

    // const oldprice = item.getElementsByClassName("css-1yxu09j")[0].textContent.trim().match(/\d+\.\d+/)[0];
    const prices = parseFloat(item.getElementsByClassName("css-p064rq")[0].textContent.trim() .match(/\d+\.\d+/)[0]);

    const quantity = parseFloat(item.getElementsByClassName("css-qc6sy-singleValue")[0].textContent.trim());

    if (quantity > 0) {
      // let _shipping = 0;
      
      let _price = parseFloat( (prices / quantity).toFixed(3) );
      // let _tax = parseFloat( (_price * parseFloat(tax)).toFixed(3));

    
      let _shipping = parseFloat( (tShipping == 0 ? 0 : ((_weight / tweight ) * tShipping ).toFixed(3))) //item.weight / pkgWeight
      // console.log('...........................................');
      // console.log('_price : ' +    typeof(_price) );
      // console.log(  '_shipping : '+    typeof(_shipping)  );
      // console.log(  'tPrice : '+    typeof(tPrice)  );
      // console.log(  'tShipping : '+    typeof(tShipping)  );
      // console.log(  'extra : '+    typeof(extra)  );
      // console.log('...........................................');
      let _extra = parseFloat( (((_price + _shipping) / (tPrice + tShipping)) * parseFloat( extra)).toFixed(3));

      let _tax = parseFloat((   ((_price + _shipping) / (tPrice + tShipping)) * totaltaxes ).toFixed(3));

      for (let i = 0; i < quantity; i++) {
        count += 1;
        const parsedItem = {
          no: count,
          name: name,
          weight: _weight,
          // oldprice: oldprice,
          price: _price,
          tax: _tax,
          shipping : _shipping,
          iextra: _extra,
          quantity: 1,
          subtotal: tPrice,
          fprice : Number((_price + _tax + _extra + _shipping).toFixed(3))
        };

        parsedItems.push(parsedItem);
      }
    }
  }

  const parsing_results = {

    items: parsedItems,
    subtotal : tPrice,
    pkg_weight: tweight,
    pkg_shipping : tShipping,
    rewards : rewards,
    real_total_price : r_total_price
  }

  const jsonData = JSON.stringify(parsedItems, null, 4);

  // console.log(jsonData);
  return  parsing_results;  //parsedItems ;
}

export default parsingCart;
