import React from 'react'
import { useState } from "react";
import logo from '../logo.svg';
import { Link } from 'react-router-dom';

export default function Menu(params) {

    return (

        <div className='container-fluid bg-react'>

            <div className='nav-bar'>
                <ul>
                    <li className='rlg'>  <img src={logo} className="App-logo rlg" alt="logo" />               </li>
                    <li>  <Link to="/">  Home  </Link>                                         </li>
                    <li>  <Link to="/About">  About  </Link></li>
                    {/* <li>  <a href="https://yznapps.com" target='_blank'>  Services  </a>    </li>
                    <li>  <a href="https://yznapps.com" target='_blank'>  Portfolio</a>  </li> */}
                    <li>  <Link to="/Contact">  Contact  </Link></li>
                    <li>  <a href="https://cal.yznapps.com" target='_blank'>  Item-Grouper</a>  </li>
                    <li className='rlg'>  <img src={logo} className="App-logo" alt="logo" />                </li>


                </ul>
            </div>
        </div>

    );

}