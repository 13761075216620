import React from 'react';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faShoppingCart } from '@fortawesome/free-solid-svg-icons';




const Table = ({ items }) => {



  const handleClick = () => {
    if (items != null && items.length > 0) {

      const jsonData = JSON.stringify(items, null, 2);
      const blob = new Blob([jsonData], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = new Date().toDateString() + ' cart.json';
      link.click();

      window.URL.revokeObjectURL(url); // Clean up the temporary URL

    }
    else
      alert('no items to export!!!')
  }


  return (

    <div className='table-responsive-sm'>
      <table className='ytable ytable-striped mx-auto txt-l ytable-hover mt-2 ' style={{ width: "85%" }}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>Weight</th>
            <th>Price</th>
            <th>Taxes</th>
            <th>Shipping</th>
            <th>Extra</th>
            <th>Quantity</th>
            <th>Final Price</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, i) => (
            <tr key={i}>
              <td>{item.no}</td>
              <td className='text-left txt-small'>   {item.name.substring(0, item.name.length * 2 / 3)}  </td>
              <td title='Weight '     >{item.weight}</td>
              <td title='Price '      >{item.price} &#160;  </td>
              <td title='Tax'         >{item.tax}</td>
              <td title='Shipping'    >{item.shipping}</td>
              <td title='Extra'       >{item.iextra}</td>
              <td title='Quantity'    >{item.quantity}</td>
              <td title='Final Price' >{item.fprice}</td>
            </tr>
          ))}

          <tr className='txt-big'>

            <td>  </td>
            <td>  </td>
            <td title='Package Weight '     >   {items.reduce((sWeight, item) => { sWeight += item.weight; return Number(sWeight.toFixed(4)); }, 0)} <small>kg</small>   </td>
            <td title='Items Price '      >   {items.reduce((price, item) => { price += item.price; return Number(price.toFixed(4)); }, 0)}  <small></small>  </td>
            <td title='Total Taxes'         >   {items.reduce((tax, item) => { tax += item.tax; return Number(tax.toFixed(4)); }, 0)}    </td>
            <td title='Total Shipping'    >   {items.reduce((shipping, item) => { shipping += item.shipping; return Number(shipping.toFixed(4)); }, 0)}    </td>
            <td title='Total Extra'       >   {items.reduce((iextra, item) => { iextra += item.iextra; return Number(iextra.toFixed(4)); }, 0)}    </td>
            <td title='No. of items'    >   {items.reduce((quantity, item) => { quantity += item.quantity; return Number(quantity.toFixed(4)); }, 0)}  </td>
            <td title='Final Package Price' >   {items.reduce((fprice, item) => { fprice += item.fprice; return Number(fprice.toFixed(4)); }, 0)}  &#160;  <small>JOD</small>  </td>
          </tr>
        </tbody>
      </table>

      {items.length > 0 && (
        <button onClick={handleClick} className='w-25 mbutton mt-5'>
          Export Cart to Json File  <FontAwesomeIcon className='px-2' icon={faFileExport} />
        </button>
      )}




    </div>
  );
};

export default Table;