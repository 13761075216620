import React, { useState } from 'react'

function tlegend({params}) {

    return(

        <div className='col-md-6 mt-4 mb-3 mx-auto'>

        <fieldset className='fieldset text-center'>
            <legend className='legend-content'>

                <div className='btn-group'>
                    <label className='mx-5' htmlFor=""> Total Price : {  (params.real_total_price + params.rewards).toFixed(3)  }  </label>   
                    <label className='mx-5' htmlFor=""> Rewards : { params.rewards } </label>
                    <label className='mx-5' htmlFor=""> Net To Pay : { params.real_total_price } </label>
                    
                </div>
 
                {/* <div className='btn-group'>
                    <label className='mx-5' htmlFor=""> Rewards : { params.rewards } </label>
                    <label className='mx-5' htmlFor=""> Net To Pay : {  params.real_total_price - params.rewards  }  </label>   
                </div> */}



            </legend>
        </fieldset>


        </div>
    )
    
}


export default tlegend